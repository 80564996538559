import { actionCreatorFactory } from 'typescript-fsa';
import {
	CreateEmployeeDetailsRequest,
	CreateEmployeeDetailsResponse,
	GetEmployeeDetailsRequest,
	GetEmployeeDetailsResponse,
	GetEmployeeDetailsTemplateRequest,
	GetEmployeeDetailsTemplateResponse,
	UpdateEmployeeDetailsRequest,
	UpdateEmployeeDetailsResponse,
} from './typings';

const ActionFactory = actionCreatorFactory('EMPLOYEE_DETAIL');

export const EmployeeDetailsActions = {
	get: ActionFactory.async<GetEmployeeDetailsRequest, GetEmployeeDetailsResponse, Error>('GET'),
	update: ActionFactory.async<UpdateEmployeeDetailsRequest, UpdateEmployeeDetailsResponse, Error>('UPDATE'),
	getTemplate: ActionFactory.async<GetEmployeeDetailsTemplateRequest, GetEmployeeDetailsTemplateResponse, Error>(
		'GET_TEMPLATE',
	),
	create: ActionFactory.async<CreateEmployeeDetailsRequest, CreateEmployeeDetailsResponse, Error>('CREATE'),
};
