import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ContentLoading, initialContentLoading, setFailed, setLoaded, setLoading } from '@ea/common';
import { RoleDetailed } from '../role/typings';
import { EmployeeDetailsActions } from './actions';
import { EmployeeDetailed, EmployeeDetailedDraft } from './typings';

export interface EmployeeDetailsState {
	data: ContentLoading<EmployeeDetailed | null>;
	template: ContentLoading<EmployeeDetailedDraft | null>;
	role: ContentLoading<RoleDetailed | null>;
	staff: ContentLoading<RoleDetailed | null>;
}

const initialState = (): EmployeeDetailsState => ({
	data: initialContentLoading(null),
	template: initialContentLoading(null),
	role: initialContentLoading(null),
	staff: initialContentLoading(null),
});

export const EmployeeDetailsReducer = reducerWithInitialState(initialState())
	.case(EmployeeDetailsActions.get.started, (state) => ({
		...state,
		data: setLoading(state.data, null),
	}))
	.case(EmployeeDetailsActions.get.failed, (state, { error }) => ({
		...state,
		data: setFailed(state.data, null, error),
	}))
	.case(EmployeeDetailsActions.get.done, (state, { result }) => ({
		...state,
		data: setLoaded(state.data, result),
	}))

	.case(EmployeeDetailsActions.getTemplate.started, (state) => ({
		...state,
		template: setLoading(state.template, null),
	}))
	.case(EmployeeDetailsActions.getTemplate.failed, (state, { error }) => ({
		...state,
		template: setFailed(state.template, null, error),
	}))
	.case(EmployeeDetailsActions.getTemplate.done, (state, { result }) => ({
		...state,
		template: setLoaded(state.template, result),
	}))

	.case(EmployeeDetailsActions.update.done, (state, { result }) => ({
		...state,
		data: setLoaded(state.data, result),
	}));
