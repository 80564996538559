import { actionCreatorFactory } from 'typescript-fsa';
import {
	GetEmployeeCardRequest,
	GetEmployeeCardResponse,
	GetEmployeesListRequest,
	GetEmployeesListResponse,
	RemoveEmployeesRequest,
	RestoreEmployeesRequest,
} from './typings';

const ActionFactory = actionCreatorFactory('EMPLOYEES');

export const EmployeesActions = {
	batchRemove: ActionFactory.async<RemoveEmployeesRequest, {}, Error>('BATCH_REMOVE'),
	batchRestore: ActionFactory.async<RestoreEmployeesRequest, {}, Error>('BATCH_RESTORE'),
	getList: ActionFactory.async<GetEmployeesListRequest, GetEmployeesListResponse, Error>('GET_LIST'),
	getCard: ActionFactory.async<GetEmployeeCardRequest, GetEmployeeCardResponse, Error>('GET_CARD'),
};
