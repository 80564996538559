import styled from 'astroturf';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React, { FC, MouseEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useKeyPress } from '@ea/admin/src/hooks/general/useKeyPress';
import { useLockBodyScroll } from '../../../service/hooks/useLockBodyScroll';
import { EmptyPortal } from '../../../service/portals/EmptyPortal';

interface Props {
	open?: boolean;
	onClose?: () => void;
	onClosed?: () => void;
	onOpened?: () => void;
	closeOnClickOutside?: boolean;
	noOverflow?: boolean;
	className?: string;
	usePortal?: boolean;
}

export const ModalWrapper: FC<Props> = ({
	open,
	className,
	usePortal = false,
	onClose,
	onClosed,
	onOpened,
	children,
	closeOnClickOutside,
}) => {
	const scroll = useRef<HTMLDivElement>(null);

	const ref = useRef<HTMLDivElement>(null);

	const onInnerClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
	}, []);

	const onOuterClick = useCallback(() => {
		if (closeOnClickOutside && onClose) {
			onClose();
		}
	}, [closeOnClickOutside, onClose]);

	useKeyPress('Escape', onClose);

	useLayoutEffect(() => {
		scroll.current &&
			(open
				? disableBodyScroll(scroll.current, { reserveScrollBarGap: true })
				: enableBodyScroll(scroll.current));
	}, [open]);

	useLayoutEffect(() => {
		return () => {
			clearAllBodyScrollLocks();
		};
	}, []);

	useLockBodyScroll(Boolean(open));

	const modal = (
		<CSSTransition
			appear={true}
			onExited={onClosed}
			onEntered={onOpened}
			in={open}
			nodeRef={ref}
			timeout={200}
			unmountOnExit
			classNames="appear"
		>
			<TransitionWrapper ref={ref}>
				<Overlay onClick={onOuterClick} open={open}>
					<Wrapper ref={scroll}>
						<InnerWrapper className={className} onClick={onInnerClick}>
							{children}
						</InnerWrapper>
					</Wrapper>
				</Overlay>
			</TransitionWrapper>
		</CSSTransition>
	);

	if (usePortal) {
		return <EmptyPortal id={'root'}>{modal}</EmptyPortal>;
	} else {
		return modal;
	}
};

const TransitionWrapper = styled.div`
	@import '../../../styles/constants.scss';
	z-index: $z-index-modal;
	position: relative;
`;

const Overlay = styled.div<{ open?: boolean }>`
	@import '../../../styles/constants.scss';
	background-color: rgba(255, 255, 255, 0.85);
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: $z-index-modal;
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
`;

const Wrapper = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	display: flex;
	padding: 48px 8px 126px;
	box-sizing: border-box;
`;

const InnerWrapper = styled.div<{ noOverflow?: boolean }>`
	position: relative;
	min-width: 40px;
	min-height: 40px;
	margin: auto;
	flex: 0 0 auto;
`;
