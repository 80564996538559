import React, { FC } from 'react';
import styled from 'astroturf';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PdfIcon } from '../../../images/icons/pdf.svg';
import { ReactComponent as ExcelIcon } from '../../../images/icons/excel.svg';
import { Tooltiped } from '../../service/Tooltiped';
import { WarningIcon } from '../indicators/WarningIcon';
import { DownloadUrl } from './DownloadUrl';

export interface Props {
	excel?: string;
	pdf?: string;
	warning?: string;
	className?: string;
	disabled?: boolean;
}

export const DownloadReports: FC<Props> = ({ excel, pdf, warning, disabled, className }) => {
	const { t } = useTranslation();

	return (
		<Wrapper className={className}>
			{pdf && (
				<Tooltiped text={t('common.words.pdf')}>
					<Button href={disabled ? '' : pdf} disabled={disabled}>
						<PdfIcon />
					</Button>
				</Tooltiped>
			)}
			{excel && (
				<Tooltiped text={t('common.words.excel')}>
					<Button href={disabled ? '' : excel} disabled={disabled}>
						<ExcelIcon />
					</Button>
				</Tooltiped>
			)}
			{warning && <WarningIcon text={warning} />}
		</Wrapper>
	);
};

const Wrapper = styled.span<{ disabled?: boolean }>`
	display: inline-flex;
	align-items: center;
	gap: 8px;
	&.disabled {
		opacity: 0.33;
	}
`;

const Button = styled(DownloadUrl)<{ disabled?: boolean }>`
	@import '../../../styles/constants.scss';
	display: flex;
	align-items: center;
	padding: 4px 4px;
	margin-left: -4px;
	fill: $color-blue;
	&.disabled {
		fill: $color-light-gray;
	}
`;
