import React from 'react';
import styled from 'astroturf';
import { DownloadReports } from '@ea/common';
import { AbstractRenderer } from './AbstractRenderer';
interface Props {
	value: {
		pdf?: string;
		excel?: string;
	};
	disabled?: boolean;
}
export class DownloadReportsRenderer extends AbstractRenderer {
	className = 'ag-grid-with-padding-cell ag-grid-centered-cell';

	public render(params: Props) {
		return <Renderer {...params} />;
	}
}

const Renderer = (props: Props) => {
	return <StyledDownloadRepors pdf={props.value?.pdf} excel={props.value?.excel} disabled={props.disabled} />;
};

const StyledDownloadRepors = styled(DownloadReports)`
	min-height: 100%;
	min-width: 100%;
	display: inline-flex;
	justify-content: center;
	align-items: flex-start;
	top: -4px;
	position: relative;
`;
