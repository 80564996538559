import React, { FC, useLayoutEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

interface IProps {
	visible?: boolean;
	delay?: boolean;
	onEnter?: () => void;
	onEntering?: () => void;
}

export const Appear: FC<IProps> = ({ visible, onEnter, onEntering, children, delay }) => {
	const [appear, setAppear] = useState(false);

	const timeout = delay ? 1000 : 0;

	useLayoutEffect(() => {
		const id = setTimeout(
			() => {
				setAppear(Boolean(visible));
			},
			visible ? timeout : 0,
		);
		return () => {
			clearTimeout(id);
		};
	}, [visible, timeout, appear]);

	return (
		<CSSTransition
			appear
			in={appear}
			timeout={500}
			classNames={'slow-appear'}
			onEnter={onEnter}
			onEntering={onEntering}
			unmountOnExit
		>
			<>{children}</>
		</CSSTransition>
	);
};
