import React, { FC } from 'react';
import styled from 'astroturf';

interface Props {
	value?: string | number;
	scoreColor?: string;
	className?: string;
	percent?: boolean;
}

export const EstimateScore: FC<Props> = ({ value, percent, className, scoreColor }) => {
	return (
		<Body className={className} style={{ background: scoreColor }}>
			{value}
			{percent && '%'}
		</Body>
	);
};

const Body = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 2px;

	width: 90px;
	height: 30px;
	border-radius: 2px;

	font-size: 24px;
	font-weight: 300;
`;
