import React, { FC } from 'react';
import styled from 'astroturf';
import { ReactComponent as Icon } from '../../../images/icons/warning.svg';
import { Tooltiped } from '../../service/Tooltiped';

export interface Props {
	text?: string;
}

export const WarningIcon: FC<Props> = ({ text }) => {
	return (
		<Wrapper>
			<Tooltiped text={text}>
				<StyledWarningIcon />
			</Tooltiped>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	display: flex;
	height: 16px;
`
const StyledWarningIcon = styled(Icon)`
	height: 16px;
	display: flex;
`;
