import { forEach, isBoolean } from 'lodash-es';

export function omitEmpty(obj: any) {
	const result: any = {};
	Object.keys(obj).forEach((key) => {
		if (obj[key] !== null && typeof obj[key] !== 'undefined') {
			result[key] = obj[key];
		}
	});
	return result;
}

export function countTruthy(collection: any) {
	let number = 0;
	forEach(collection, (el) => {
		if (el) {
			number += 1;
		}
	});
	return number;
}

export function clearArray<T>(obj: T[]) {
	return obj.filter((el) => el);
}

export function isNumeric(value: any): value is number | string {
	return !Number.isNaN(parseFloat(value)) && isFinite(value);
}

export function combine<T>(array: (T | boolean)[]): T[] {
	return array.filter((el) => !isBoolean(el)) as T[];
}

export function compareStrings(a: string, b: string, order?: 'asc' | 'desc') {
	if (a < b) {
		return order === 'asc' ? 1 : -1;
	} else if (a > b) {
		return order === 'asc' ? -1 : 1;
	} else {
		return 0;
	}
}

export function inPlace<T>(calculation: () => T): T {
	return calculation();
}
