import React, { FC } from 'react';
import { LoadingIndicator } from '../elements/indicators/LoadingIndicator';
import { Appear } from './Appear';

interface IProps {
	loading?: boolean;
	onEnter?: () => void;
	onEntering?: () => void;
	hideIndicator?: boolean;
}

export const LoadingContent: FC<IProps> = ({ children, hideIndicator, loading, onEnter, onEntering }) => {
	return (
		<>
			<Appear visible={!loading} onEnter={onEnter} onEntering={onEntering}>
				{children}
			</Appear>
			{!hideIndicator && (
				<Appear visible={loading} delay={true}>
					<LoadingIndicator />
				</Appear>
			)}
		</>
	);
};
