import styled from 'astroturf';
import { ReactComponent as Bars } from '../../../images/indicators/bars.svg';

export const LoadingIndicator = styled(Bars)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 40px;
`;
