import React, { ChangeEvent, FC, KeyboardEvent, memo } from 'react';
import { isNumeric } from '@ea/common';
import { SimpleInput } from './SimpleInput';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
	invalid?: boolean;
	decimal?: boolean;
	min?: number;
	max?: number;
}

export const NumberInput: FC<IProps> = memo(({ onChange, decimal, value, min, max, ...rest }) => {
	const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const { value: val } = event.target;

		if ((event.target.validity.valid || val === '' || val === '-') && onChange) {
			const v = String(val.replace(',', '.'));
			const r = val === '' || val === '-' ? '' : v.length > 1 ? v.replace(/^0+/, '0') : v;

			if (isNumeric(min) && Number(r) < min) {
				event.target.value = String(min);
			} else if (isNumeric(max) && Number(r) > max) {
				event.target.value = String(max);
			} else {
				event.target.value = r;
			}
			onChange(event);
		}
	};

	return (
		<SimpleInput
			{...rest}
			value={isNumeric(value) ? value : ''}
			pattern={decimal ? '^-?[0-9]\\d{0,12}([\\.,]\\d{0,6})?$' : '^\\d+$'}
			onChange={onChangeHandler}
		/>
	);
});
