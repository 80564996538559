import { actionCreatorFactory } from 'typescript-fsa';
import {
	CreateStaffDetailsRequest,
	CreateStaffDetailsResponse,
	GetStaffDetailsRequest,
	GetStaffDetailsResponse,
	GetStaffDetailsTemplateRequest,
	GetStaffDetailsTemplateResponse,
	UpdateStaffDetailsRequest,
	UpdateStaffDetailsResponse,
} from './typings';

const ActionFactory = actionCreatorFactory('STAFF_DETAIL');

export const StaffDetailsActions = {
	get: ActionFactory.async<GetStaffDetailsRequest, GetStaffDetailsResponse, Error>('GET'),
	update: ActionFactory.async<UpdateStaffDetailsRequest, UpdateStaffDetailsResponse, Error>('UPDATE'),
	create: ActionFactory.async<CreateStaffDetailsRequest, CreateStaffDetailsResponse, Error>('CREATE'),
	getTemplate: ActionFactory.async<GetStaffDetailsTemplateRequest, GetStaffDetailsTemplateResponse, Error>(
		'GET_TEMPLATE',
	),
};
