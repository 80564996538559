import React, { FC } from 'react';

export interface Props {
	href?: string;
	className?: string;
}

export const DownloadUrl: FC<Props> = ({ href, className, children }) => {
	if (href) {
		return (
			<a className={className} download={true} target={'_blank'} href={href} rel="noreferrer">
				{children}
			</a>
		);
	} else {
		return <span className={className}>{children}</span>;
	}
};
