import { actionCreatorFactory } from 'typescript-fsa';
import { Dict } from '@ea/common';
import {
	GetRoleCardRequest,
	GetRoleCardResponse,
	GetRoleEmployeesRequest,
	GetRoleEmployeesResponse,
	GetRolesListRequest,
	GetRolesListResponse,
	RemoveRolesRequest,
	RestoreRolesRequest,
} from './typings';

const ActionFactory = actionCreatorFactory('ROLES');

export const RolesActions = {
	getRoles: ActionFactory.async<{}, Dict[], Error>('GET_ROLES'),
	batchRemove: ActionFactory.async<RemoveRolesRequest, {}, Error>('BATCH_REMOVE'),
	batchRestore: ActionFactory.async<RestoreRolesRequest, {}, Error>('BATCH_RESTORE'),
	getList: ActionFactory.async<GetRolesListRequest, GetRolesListResponse, Error>('GET_LIST'),
	getCard: ActionFactory.async<GetRoleCardRequest, GetRoleCardResponse, Error>('GET_CARD'),
	getEmployees: ActionFactory.async<GetRoleEmployeesRequest, GetRoleEmployeesResponse, Error>('GET_EMPLOYEES'),
};
