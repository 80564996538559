import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ContentLoading, initialContentLoading } from '@ea/common';
import { RoleDetailsActions } from './actions';
import { RoleDetailed, RoleDetailedDraft } from './typings';

export interface RoleDetailsState {
	data: ContentLoading<RoleDetailed | null>;
	template: ContentLoading<RoleDetailedDraft | null>;
}

const initialState = (): RoleDetailsState => ({
	data: initialContentLoading(null),
	template: initialContentLoading(null),
});

export const RoleDetailsReducer = reducerWithInitialState(initialState())
	.case(RoleDetailsActions.get.started, (state) => ({
		...state,
		data: {
			content: null,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(RoleDetailsActions.get.failed, (state, { error }) => ({
		...state,
		data: {
			content: null,
			loading: {
				status: 'loaded',
			},
			error,
		},
	}))
	.case(RoleDetailsActions.get.done, (state, { result }) => ({
		...state,
		data: {
			content: result,
			loading: {
				status: 'loaded',
			},
		},
	}))
	.case(RoleDetailsActions.getTemplate.started, (state) => ({
		...state,
		template: {
			loading: {
				status: 'loading',
			},
			content: state.template.content,
		},
	}))
	.case(RoleDetailsActions.getTemplate.failed, (state, { error }) => ({
		...state,
		template: {
			loading: {
				status: 'loaded',
			},
			content: null,
			error,
		},
	}))
	.case(RoleDetailsActions.getTemplate.done, (state, { result }) => ({
		...state,
		template: {
			content: result,
			loading: {
				status: 'loaded',
			},
		},
	}))
	.case(RoleDetailsActions.update.done, (state, { result }) => ({
		...state,
		data: {
			content: result,
			loading: {
				status: 'loaded',
			},
		},
	}));
