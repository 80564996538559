import { DependencyList, useEffect, useState } from 'react';

export function useComputed<T>(generator: () => T, dependencies: DependencyList) {
	const [state, setState] = useState(generator());

	useEffect(() => {
		setState(generator());
	}, dependencies);

	return state;
}
