import { actionCreatorFactory } from 'typescript-fsa';
import {
	GetStaffCardRequest,
	GetStaffCardResponse,
	GetStaffEmployeesRequest,
	GetStaffEmployeesResponse,
	GetStaffListRequest,
	GetStaffListResponse,
	RemoveStaffRequest,
	RestoreStaffRequest,
} from './typings';

const ActionFactory = actionCreatorFactory('STAFF');

export const StaffActions = {
	batchRemove: ActionFactory.async<RemoveStaffRequest, {}, Error>('BATCH_REMOVE'),
	batchRestore: ActionFactory.async<RestoreStaffRequest, {}, Error>('BATCH_RESTORE'),
	getList: ActionFactory.async<GetStaffListRequest, GetStaffListResponse, Error>('GET_LIST'),
	getCard: ActionFactory.async<GetStaffCardRequest, GetStaffCardResponse, Error>('GET_CARD'),
	getEmployees: ActionFactory.async<GetStaffEmployeesRequest, GetStaffEmployeesResponse, Error>('GET_EMPLOYEES'),
};
