import { actionCreatorFactory } from 'typescript-fsa';
import {
	CreateRoleDetailsRequest,
	CreateRoleDetailsResponse,
	GetRoleDetailsRequest,
	GetRoleDetailsResponse,
	GetRoleDetailsTemplateRequest,
	GetRoleDetailsTemplateResponse,
	UpdateRoleDetailsRequest,
	UpdateRoleDetailsResponse,
} from './typings';

const ActionFactory = actionCreatorFactory('ROLE_DETAIL');

export const RoleDetailsActions = {
	get: ActionFactory.async<GetRoleDetailsRequest, GetRoleDetailsResponse, Error>('GET'),
	update: ActionFactory.async<UpdateRoleDetailsRequest, UpdateRoleDetailsResponse, Error>('UPDATE'),
	create: ActionFactory.async<CreateRoleDetailsRequest, CreateRoleDetailsResponse, Error>('CREATE'),
	getTemplate: ActionFactory.async<GetRoleDetailsTemplateRequest, GetRoleDetailsTemplateResponse, Error>(
		'GET_TEMPLATE',
	),
};
