import React, { FC, useMemo, useRef, useState } from 'react';
import styled from 'astroturf';
import { isString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '@ea/admin/src/components/general/elements/fields/NumberInput';
import { SimpleDropdown } from '../../fields/SimpleDropdown';
import { SelectMenu } from '../../../service/Select/SelectMenu';
import { SelectMenuWrapper } from '../../../service/Select/SelectMenuWrapper';
import { ReactComponent as RemoveIcon } from '../../../../images/icons/grid/remove-column.svg';
import { SelectItems } from '../../../service/Select/SelectItems';
import { TextButton } from '../../buttons/TextButton';
import { ReactComponent as AddIcon } from '../../../../images/icons/actions/add-line.svg';
import { CircleButton } from '../../buttons/CircleButton';
import { isNumeric } from '../../../../service/utils/service';

type Value = {
	name?: string | number;
};

export interface ListEditorProps {
	value?: Value[];
	placeholder?: string;
	disabled?: boolean;
	border?: boolean;
	onChange?: (item: Value[]) => void;
	onBlur?: (e: React.FocusEvent<any>) => void;
	className?: string;
	disablePortal?: boolean;
	error?: string;
	touched?: boolean;
	multiline?: boolean;
	sameWidth?: boolean;
	grow?: boolean;
	type?: 'number' | 'string';
}

export const ListEditor: FC<ListEditorProps> = ({
	className,
	placeholder,
	value,
	disabled,
	onChange,
	disablePortal,
	onBlur,
	touched,
	error,
	multiline,
	sameWidth,
	grow,
	border,
	type = 'string',
}) => {
	const { t } = useTranslation();
	const container = useRef<HTMLDivElement>(null);

	const [open, setOpen] = useState(false);

	const onClick = () => {
		if (open) {
			setOpen(false);
		} else {
			setOpen(true);
		}
	};

	const onChangeItem = (index: number, name: string) => {
		if (value) {
			onChange?.(value.map((el, elIndex) => (elIndex === index ? { ...el, name } : el)));
		}
	};

	const onRemoveItem = (index: number) => {
		if (value) {
			onChange?.(value.filter((el, elIndex) => elIndex !== index));
		}
	};
	const onAddItem = () => {
		if (value) {
			onChange?.([...value, { name: undefined }]);
		}
	};

	const dropdownLabel = useMemo(() => {
		if (value && value.length > 0) {
			if (value.length > 1) {
				return `${t('common.ui.selected')}: ${value.length}`;
			} else {
				return isString(value[0].name) ? value[0].name.trim() : '';
			}
		}
	}, [value, type]);

	return (
		<Wrapper grow={grow}>
			<SimpleDropdown
				ref={container}
				className={className}
				value={dropdownLabel}
				disabled={disabled}
				open={open}
				placeholder={placeholder || 'Введите значения'}
				onClick={onClick}
				onBlur={onBlur}
				touched={touched}
				error={error}
				multiline={multiline}
				border={border}
			/>
			<StyledSelectMenu
				onClickOutside={() => setOpen(false)}
				processClickOutside={open}
				anchor={container}
				open={open}
				disablePortal={disablePortal}
				limitHeight
				sameWidth={sameWidth}
			>
				<StyledSelectMenuWrapper>
					{value?.length ? (
						<StyledSelectItems>
							{value?.map((item, index) => (
								<Content key={index}>
									{type === 'number' ? (
										<StyledNumberInput
											value={item.name}
											onChange={(event) => onChangeItem(index, event.target.value)}
											decimal={true}
										/>
									) : (
										<StyledInput
											value={item.name}
											onChange={(event) => onChangeItem(index, event.target.value)}
										/>
									)}
									<Icons>
										<CircleButton onClick={() => onRemoveItem(index)}>
											<RemoveIcon />
										</CircleButton>
									</Icons>
								</Content>
							))}
						</StyledSelectItems>
					) : null}
					<AddButton icon={<AddIcon />} onClick={onAddItem}>
						{t('actions.add')}
					</AddButton>
				</StyledSelectMenuWrapper>
			</StyledSelectMenu>
		</Wrapper>
	);
};

const StyledSelectMenu = styled(SelectMenu)`
	max-width: 576px;
	min-width: 192px;
`;

const StyledSelectMenuWrapper = styled(SelectMenuWrapper)`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const Icons = styled.div`
	display: flex;
	gap: 12px;
	margin-left: 8px;
`;

const Wrapper = styled.div<{ grow?: boolean }>`
	@import '../../../../styles/constants.scss';
	position: relative;
	&.grow {
		min-width: 0;
		width: 100%;
	}
`;

const StyledSelectItems = styled(SelectItems)`
	display: flex;
	flex-direction: column;
	border-bottom: solid 1px #eee;
	gap: 8px;
	margin-bottom: 0;
	padding-bottom: 10px;
`;

const AddButton = styled(TextButton)`
	text-align: left;
	padding: 12px 12px;
	justify-content: left;
`;

const StyledInput = styled.input`
	@import '../../../../styles/constants.scss';
	border: solid 1px #eee;
	outline: 0;
	min-width: 0;
	text-overflow: ellipsis;
	padding-left: 8px;
	font-size: 12px;
	width: 100%;
	height: 32px;
`;

const StyledNumberInput = styled(NumberInput)`
	@import '../../../../styles/constants.scss';
	border: solid 1px #eee;
	outline: 0;
	min-width: 0;
	text-overflow: ellipsis;
	padding-left: 8px;
	font-size: 12px;
	width: 100%;
	height: 32px;
`;

const Content = styled.div`
	display: flex;
	padding-left: 8px;
	padding-right: 8px;
	align-items: center;
`;
