import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ContentLoading, initialContentLoading } from '@ea/common';
import { StaffDetailsActions } from './actions';
import { StaffDetailed, StaffDetailedDraft } from './typings';

export interface StaffDetailsState {
	data: ContentLoading<StaffDetailed | null>;
	template: ContentLoading<StaffDetailedDraft | null>;
}

const initialState = (): StaffDetailsState => ({
	data: initialContentLoading(null),
	template: initialContentLoading(null),
});

export const StaffDetailsReducer = reducerWithInitialState(initialState())
	.case(StaffDetailsActions.get.started, (state) => ({
		...state,
		data: {
			content: null,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(StaffDetailsActions.get.failed, (state, { error }) => ({
		...state,
		data: {
			content: null,
			loading: {
				status: 'loaded',
			},
			error,
		},
	}))
	.case(StaffDetailsActions.get.done, (state, { result }) => ({
		...state,
		data: {
			content: result,
			loading: {
				status: 'loaded',
			},
		},
	}))
	.case(StaffDetailsActions.update.done, (state, { result }) => ({
		...state,
		data: {
			content: result,
			loading: {
				status: 'loaded',
			},
		},
	}))
	.case(StaffDetailsActions.getTemplate.started, (state) => ({
		...state,
		template: {
			loading: {
				status: 'loading',
			},
			content: state.template.content,
		},
	}))
	.case(StaffDetailsActions.getTemplate.failed, (state, { error }) => ({
		...state,
		template: {
			loading: {
				status: 'loaded',
			},
			content: null,
			error,
		},
	}))
	.case(StaffDetailsActions.getTemplate.done, (state, { result }) => ({
		...state,
		template: {
			content: result,
			loading: {
				status: 'loaded',
			},
		},
	}));
